@import '~static/styles/mixins';
@import '~static/styles/variables';

.Header {
  position: relative;
  max-width: 1880px;
  // max-height: 1017px;
  margin: 0 auto 130px;

  @include respond-below(xxl) {
    margin: 0 auto 60px;
    max-width: 1326px;
  }

  @include respond-below(xl) {
    margin: 0 auto 80px;
    max-width: 728px;
  }

  @include respond-below(md) {
    margin: 0 auto 60px;
    max-width: 350px;
  }

  .image {
    max-height: 100%;
    max-width: 100%;

    @include respond-below(xl) {
      content: url("~static/images/header/header-bg-xl.png");
    }

    @include respond-below(md) {
      content: url("~static/images/header/header-bg-md.png");
    }
  }

  .menu {
    position: absolute;
    width: 100%;
    padding: 0 80px;
    top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-below(xxl) {
      padding: 0 40px;
      top: 30px;
    }

    @include respond-below(xl) {
      display: none;
    }

    .lang {
      font-weight: 300;
      font-size: 20px;
      color: rgba($white, 0.6);

      @include respond-below(xxl) {
        font-size: 14px;
      }
    }

    .navbar {
      display: flex;
      align-items: center;
      gap: 50px;

      @include respond-below(xxl) {
        gap: 30px;
      }

      .button {
        position: relative;

        font-weight: 300;
        font-size: 20px;
        color: $white;
        text-decoration: none;
        transition: all 0.3s ease 0s;

        @include respond-below(xxl) {
          font-size: 14px;
        }

        &:hover {
          color: $light-green;

          &:after {
            // visibility: visible;
            width: 50%;

          }
        }

        &:after {
          // visibility: hidden;
          content: "";
          position: absolute;
          bottom: -10px;
          // left: 50%;
          left: 0;
          width: 0%;
          height: 4px;
          background-color: $light-green;
          border-radius: 10px;
          transition: all 0.3s ease 0s;
        }
      }

      .logo {
        margin: 0 30px;

        @include respond-below(xxl) {
          margin: 0 20px;
          width: 137px;
          height: 45px;

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .redirect {
      display: flex;
      gap: 4px;
      align-items: center;

      text-decoration: none;

      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #91F4A9;

        @include respond-below(xxl) {
          font-size: 14px;
        }
      }
    }
  }

  .mobileMenu {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 0 40px;
    top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-above(xl) {
      display: none;
    }

    @include respond-below(md) {
      padding: 0 15px;
      top: 20px;
    }

    .lang {
      font-weight: 300;
      font-size: 14px;
      line-height: 135%;
      color: rgba($white, 0.6);
    }

    .logo {
      margin: 0 20px;
      width: 137px;
      height: 45px;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }


    .burger {
      // padding: 10px;
    }
  }

  .briefing {
    position: absolute;
    left: 0;
    right: 0;
    // top: calc(50% - 212px);
    top: 326px;
    margin: auto;

    max-width: 1460px;
    font-weight: 600;
    font-size: 96px;
    text-align: center;
    color: $white;

    @include respond-below(xxl) {
      font-size: 64px;
      max-width: 664px;
      top: 206px;
      // top: calc(60% - 212px);
    }

    @include respond-below(xl) {
      top: 372px;
    }

    @include respond-below(md) {
      font-size: 48px;
      top: 216px;
    }

  }

  .payment {
    position: absolute;
    left: 80px;
    bottom: 200px;

    display: flex;
    flex-direction: column;

    @include respond-below(xxl) {
      left: 40px;
      bottom: 120px;
    }

    @include respond-below(xl) {
      display: none;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 12px;

      .icon {
        height: 38px;

        @include respond-below(xxl) {
          height: 32px;
        }
      }

      span {
        font-weight: 600;
        font-size: 48px;
        line-height: 73px;
        color: $white;

        @include respond-below(xxl) {
          font-size: 36px;
          line-height: 54px;
        }
      }
    }

    .text {
      display: flex;
      align-items: center;
      gap: 8px;

      .icon {
        height: 10px;

        @include respond-below(xxl) {
          height: 8px;
        }
      }

      span {
        font-weight: 300;
        font-size: 20px;
        line-height: 130%;
        color: #8C9A90;

        @include respond-below(xxl) {
          font-size: 14px;
          line-height: 135%;
        }
      }
    }
  }

  .registrant {
    position: absolute;
    right: 40px;
    bottom: 120px;

    color: rgba(255, 255, 255, 0.8);
    
    display: flex;
    flex-flow: column nowrap;
    width: 360px;

    p {
      font-size: 16px;
    }
    
    span {
      font-size: 14px;
    }

    @include respond-below(xxl) {
      width: 340px;
      bottom: 120px;
      font-size: 14px;
    }

    @include respond-below(xl) {
      left: 0;
      right: 0;
      margin: auto;

      width: 540px;
      bottom: 420px;

      p {
        font-size: 18px;
      }

      span {
        font-size: 14px;
      }
    }

    @include respond-below(md) {
      width: 220px;
      font-size: 8px;
      bottom: 210px;

      p {
        font-size: 12px;
      }

      span {
        font-size: 10px;
      }
    }
  }

  .portal {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;

    bottom: 30px;
    width: 236px;
    height: 236px;

    @include respond-below(xxl) {
      width: 160px;
      height: 160px;
      bottom: 20px;
    }

    @include respond-below(xl) {
      width: 140px;
      height: 140px;
      bottom: 20px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}