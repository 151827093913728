@import '~static/styles/mixins';
@import '~static/styles/variables';

.PaymentSystems {
  max-width: 1880px;
  margin: 0 auto 200px;
  padding: 0 80px;

  @include respond-below(xxl) {
    margin: 0 auto 160px;
    padding: 0 60px;
    max-width: 1326px;
  }

  @include respond-below(xl) {
    padding: 0 40px;
    max-width: 728px;
  }

  @include respond-below(md) {
    padding: 0 15px;
    max-width: 350px;
    margin: 0 auto 100px;
  }

  .article {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 120px;

    @include respond-below(xxl) {
      gap: 5px;
      margin-bottom: 60px;
    }

    @include respond-below(md) {
      margin-bottom: 40px;
    }

    .title {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: $green;
      text-align: center;

      @include respond-below(xxl) {
        font-size: 18px;
        line-height: 27px;
      }

      @include respond-below(md) {
        font-size: 14px;
      }
    }

    .text {
      max-width: 652px;
      font-weight: 600;
      font-size: 64px;
      line-height: 110%;
      color: $black;
      text-align: center;

      @include respond-below(xxl) {
        max-width: 502px;
        font-size: 48px;
      }

      @include respond-below(md) {
        max-width: auto;
        font-size: 32px;
      }
    }
  }

  .group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 120px;
    margin-bottom: 60px;

    @include respond-below(xxl) {
      gap: 78px;
      margin-bottom: 40px;
    }

    @include respond-below(xl) {
      gap: 50px;
    }

    @include respond-below(md) {
      gap: 30px;
      flex-wrap: wrap;
    }

    .icon1 {
      @include respond-below(md) {
        width: 93px;
        height: 30px;
      }
    }

    .icon2 {
      @include respond-below(md) {
        width: 58px;
        height: 45px;
      }
    }

    .icon3 {
      @include respond-below(md) {
        width: 58px;
        height: 35px;
      }
    }

    .icon4 {
      @include respond-below(md) {
        width: 55px;
        height: 55px;
      }
    }

    .icon5 {
      @include respond-below(md) {
        width: 97px;
        height: 35px;
      }
    }
  }

  .group2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;

    @include respond-below(xl) {
      gap: 50px;
    }

    @include respond-below(md) {
      gap: 23px;
      flex-wrap: wrap;
    }

    .icon {
      width: 100px;
      height: 100px;

      @include respond-below(xxl) {
        width: 79px;
        height: 79px;
      }

      @include respond-below(xl) {
        width: 55px;
        height: 55px;
      }

      @include respond-below(md) {
        width: 36px;
        height: 36px;
      }

      &:last-of-type {

        @include respond-below(md) {
          width: 100%;
          height: 65px;
          margin: 0 auto;
        }
      }
    }
  }
}