@import '~static/styles/mixins';
@import '~static/styles/variables';

.BestConditions {
  max-width: 1880px;
  margin: 0 auto 230px;

  @include respond-below(xxl) {
    margin: 0 auto 120px;
    max-width: 1326px;
  }

  @include respond-below(xl) {
    max-width: 728px;
  }

  @include respond-below(md) {
    margin: 0 auto 80px;
    max-width: 350px;
  }

  .article {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 80px;
    margin-bottom: 180px;

    @include respond-below(xxl) {
      gap: 5px;
      margin-bottom: 60px;
    }

    @include respond-below(md) {
      margin-bottom: 30px;
      padding: 0;
    }

    .heading {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: #35A751;

      @include respond-below(xxl) {
        font-size: 18px;
      }

      @include respond-below(xl) {
        text-align: center;
      }

      @include respond-below(md) {
        font-size: 14px;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-below(xl) {
        flex-direction: column;
        text-align: center;
        gap: 20px;
      }

      @include respond-below(md) {
        gap: 15px;
      }

      .title {
        max-width: 530px;
        font-weight: 600;
        font-size: 64px;
        line-height: 110%;
        color: $black;

        @include respond-below(xxl) {
          max-width: 428px;
          font-size: 48px;
        }

        @include respond-below(md) {
          max-width: 320px;
          font-size: 32px;
        }
      }

      .text {
        max-width: 682px;
        font-weight: 300;
        font-size: 24px;
        line-height: 130%;
        color: $gray;

        @include respond-below(xxl) {
          max-width: 429px;
          font-size: 18px;
        }

        @include respond-below(md) {
          max-width: 320px;
          font-size: 14px;
        }
      }

    }
  }

  .group {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 0 80px;

    @include respond-below(xxl) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 40px;
    }

    @include respond-below(xl) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      padding: 0 40px;
      gap: 10px;
    }

    @include respond-below(md) {
      padding: 0 15px;
    }

    .cardWrap {
      padding-bottom: 6px;
      border-radius: 30px;

      &:nth-child(2n) {
        margin-top: 100px;

        @include respond-below(xxl) {
          margin-top: 0;
        }
      }

      &.red {
        background-color: #F73C3C;

        .card {
          background: radial-gradient(40.25% 55.44% at 96.79% 6.29%,
              rgba(255, 0, 0, 0.3) 0%,
              rgba(255, 0, 0, 0) 100%), #141715;
        }
      }

      &.yellow {
        background-color: #F4E34A;

        .card {
          background: radial-gradient(40.25% 55.44% at 96.79% 6.29%,
              rgba(255, 229, 0, 0.2) 0%,
              rgba(255, 229, 0, 0) 100%), #141715;
        }
      }

      &.green {
        background-color: #4BDB6E;

        .card {
          background: radial-gradient(40.25% 55.44% at 96.79% 6.29%,
              rgba(66, 255, 0, 0.21) 0%,
              rgba(66, 255, 0, 0) 100%), #141715;
        }
      }

      &.orange {
        background-color: #FFB800;

        .card {
          background: radial-gradient(40.25% 55.44% at 96.79% 6.29%,
              rgba(255, 184, 0, 0.25) 0%,
              rgba(255, 184, 1, 0) 100%), #141715;
        }
      }

      .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px;
        gap: 20px;

        border-radius: 30px;
        max-width: 415px;

        @include respond-below(xxl) {
          max-width: 613px;
          height: 270px;
        }

        @include respond-below(xl) {
          max-width: 648px;
          height: 259px;
        }

        @include respond-below(md) {
          gap: 10px;
          padding: 20px;
          max-width: 320px;
          height: auto;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 78px;
          height: 78px;
          padding: 5px;

          svg {
            width: 100%;
            height: 100%;
          }

          @include respond-below(xxl) {
            width: 66px;
            height: 66px;
          }
        }

        .title {
          font-weight: 700;
          font-size: 20px;
          color: $white;

          @include respond-below(xxl) {
            font-size: 24px;
          }

          @include respond-below(md) {
            font-size: 20px;
          }
        }

        .text {
          font-weight: 300;
          font-size: 16px;
          line-height: 130%;
          color: $white;

          @include respond-below(md) {
            font-size: 14px;
          }
        }

        .background {
          border-radius: 30px;
        }
      }
    }
  }
}