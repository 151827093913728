@import '~static/styles/mixins';
@import '~static/styles/variables';

.Advantages {
  max-width: 1880px;
  margin: 0 auto 230px;
  padding: 0 80px;

  @include respond-below(xxl) {
    margin: 0 auto 150px;
    padding: 0 60px;
    max-width: 1326px;
  }

  @include respond-below(xl) {
    padding: 0 40px;
    max-width: 728px;
  }

  @include respond-below(md) {
    padding: 0 15px;
    max-width: 350px;
  }

  .article {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 180px;

    @include respond-below(xxl) {
      gap: 5px;
      margin-bottom: 80px;
    }

    @include respond-below(xl) {
      margin-bottom: 60px;
    }

    @include respond-below(md) {
      margin-bottom: 40px;
    }

    .heading {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: #35A751;

      @include respond-below(xxl) {
        font-size: 18px;
        line-height: 27px;
      }

      @include respond-below(xl) {
        text-align: center;
      }

      @include respond-below(md) {
        font-size: 14px;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-below(xl) {
        flex-direction: column;
        text-align: center;
        gap: 20px;
      }

      @include respond-below(md) {
        gap: 15px;
      }

      .title {
        font-weight: 600;
        font-size: 64px;
        color: $black;

        @include respond-below(xxl) {
          font-size: 48px;
        }

        @include respond-below(md) {
          font-size: 32px;
          line-height: 110%;
        }
      }

      .text {
        max-width: 593px;
        font-weight: 300;
        font-size: 24px;
        line-height: 150%;
        color: $gray;

        @include respond-below(xxl) {
          max-width: 429px;
          font-size: 18px;
          line-height: 130%;
        }

        @include respond-below(xl) {
          max-width: 594px;
        }

        @include respond-below(md) {
          max-width: 320px;
          font-size: 14px;
        }
      }

    }
  }

  .group {
    display: flex;
    justify-content: center;
    gap: 60px;

    @include respond-below(xxl) {
      gap: 40px;
    }

    @include respond-below(xl) {
      flex-direction: column;
      gap: 20px;
    }

    @include respond-below(md) {
      gap: 10px;
    }

    .cardWrap {
      padding-bottom: 6px;
      border-radius: 30px;

      &.red {
        background-color: #F73C3C;

        .card {
          background: radial-gradient(40.25% 55.44% at 96.79% 6.29%,
              rgba(255, 0, 0, 0.3) 0%,
              rgba(255, 0, 0, 0) 100%), #141715;
        }
      }

      &.green {
        background-color: #4BDB6E;

        .card {
          background: radial-gradient(40.25% 55.44% at 96.79% 6.29%,
              rgba(66, 255, 0, 0.21) 0%,
              rgba(66, 255, 0, 0) 100%), #141715;
        }
      }

      &.yellow {
        background-color: #F4E34A;

        .card {
          background: radial-gradient(40.25% 55.44% at 96.79% 6.29%,
              rgba(255, 184, 0, 0.25) 0%,
              rgba(255, 184, 1, 0) 100%), #141715;
        }
      }
    }


    .card {
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 40px 138px;
      max-width: 533px;

      @include respond-below(xxl) {
        padding: 40px 94px;
        max-width: 389px;
        gap: 0;
      }

      @include respond-below(xl) {
        padding: 30px 138px;
        max-width: 648px;
        flex-direction: row;
        align-items: center;
        gap: 15px;
      }

      @include respond-below(md) {
        padding: 30px 56px 30px;
        flex-direction: column;
        gap: 10px;
      }

      .title {
        font-weight: 600;
        font-size: 72px;
        color: #4BDB6E;
        text-align: center;

        span {
          font-size: 48px;
        }
      }

      .text {
        font-weight: 300;
        font-size: 20px;
        line-height: 130%;
        text-align: center;
        color: $white;

        @include respond-below(xxl) {
          font-size: 18px;
        }

        @include respond-below(xl) {
          text-align: left;
        }

        @include respond-below(md) {
          text-align: center;
        }
      }
    }
  }
}