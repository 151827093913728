@import '~static/styles/mixins';
@import '~static/styles/variables';

.MainPage {
  min-height: 100%;
  width: 100%;
  background-color: $white;
  padding: 20px;

  @include respond-below(md) {
    padding: 5px;
  }
}