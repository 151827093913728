@import '~static/styles/mixins';
@import '~static/styles/variables';

.overlay {
  position: fixed;
  flex: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: $modal-overlay-layer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 0;
  background: rgba($white, 0.8);

  @include respond-above(md) {
    padding: 80px 0;
  }

  @include respond-above(xxl) {
    padding: 100px 0;
  }
}

.card {
  max-width: calc(100% - 30px);
  padding: 24px 16px 16px;
  margin: auto;
  z-index: $modal-content-layer;
  background: $white;
  border-radius: 16px;
}

.MobileMenu {
  height: 100vh;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);

  .card {
    height: 100%;
    background-color: transparent;
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
  }

  .button {
    font-family: $prompt;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: $black;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: $light-green;
    }

    &:active {
      color: $light-green;
    }
  }

  .close {
    top: 40px;
    right: 40px;
    position: absolute;
    // border-radius: 100px;
    // width: 162px;
    // height: 42px;
    border: 0;
    color: $black;
    background-color: transparent;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: $light-green;

    }

    svg {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}