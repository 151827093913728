@import '~static/styles/mixins';
@import '~static/styles/variables';

.PopularMethods {
  max-width: 1880px;
  margin: 0 auto 180px;
  padding: 120px 80px;

  background-image: url("~static/images/popular-methods/background.svg");
  background-repeat: no-repeat;
  background-position: center;
  // background-size: 100%;
  background-size: cover;
  border-radius: 30px;

  @include respond-below(xxl) {
    padding: 80px 40px;
    margin: 0 auto 120px;
    max-width: 1326px;
  }

  @include respond-below(xl) {
    max-width: 728px;
  }

  @include respond-below(md) {
    padding: 60px 20px;
    margin: 0 auto 80px;
    max-width: 350px;
  }

  .article {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 100px;

    @include respond-below(xxl) {
      gap: 5px;
      margin-bottom: 60px;
    }

    @include respond-below(md) {
      margin-bottom: 30px;
      padding: 0;
    }

    .heading {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: #35A751;

      @include respond-below(xxl) {
        font-size: 18px;
      }

      @include respond-below(xl) {
        text-align: center;
      }

      @include respond-below(md) {
        font-size: 14px;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-below(xl) {
        flex-direction: column;
        text-align: center;
        gap: 20px;
      }

      @include respond-below(md) {
        gap: 15px;
      }

      .title {
        max-width: 587px;
        font-weight: 600;
        font-size: 64px;
        color: $white;

        @include respond-below(xxl) {
          max-width: 460px;
          font-size: 48px;
        }

        @include respond-below(md) {
          max-width: 320px;
          font-size: 32px;
        }
      }

      .text {
        width: 533px;
        font-weight: 300;
        font-size: 24px;
        color: rgba(255, 255, 255, 0.6);

        @include respond-below(xxl) {
          width: 429px;
          font-size: 18px;
        }

        @include respond-below(xl) {
          width: 568px;
        }

        @include respond-below(md) {
          max-width: 320px;
          font-size: 14px;
        }
      }
    }
  }

  .group {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @include respond-below(xl) {
      display: none;
    }

    .card {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .mobileGroup {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include respond-above(xl) {
      display: none;
    }

    @include respond-below(md) {
      gap: 15px;
    }

    .card {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}