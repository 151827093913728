// Fonts
$prompt: "Prompt", sans-serif;
$unbounded: "Unbounded", sans-serif;

// Colors
$white: #F0F5EF;
$green: #3DB55A;
$dark-green:  #35A751;
$light-green: #4BDB6E;
$gray: #564E4E;
$black: #000000;


// Breakpoints
// $breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1600px,
// );

$breakpoints: (
  xs: 360px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1366px,
  xxl: 1920px,
);

// Layers
$modal-overlay-layer: 100;
$modal-content-layer: 101;
$menu-overlay-layer: 10;
$menu-content-layer: 11;
$dropdown-layer: 1000;
