@import '~static/styles/mixins';
@import '~static/styles/variables';

.Footer {
  max-width: 1880px;
  height: 487px;

  // padding: 80px;
  margin: 0 auto;

  background: #141715;
  border-radius: 30px;

  position: relative;

  @include respond-below(xxl) {
    max-width: 1326px;
    height: 454px;
  }

  @include respond-below(xl) {
    max-width: 728px;
    height: 752px;
  }

  @include respond-below(md) {
    max-width: 350px;
    height: 1092px;
  }

  .logo {
    position: absolute;
    top: 80px;
    left: 80px;

    @include respond-below(xxl) {
      top: 50px;
      left: 40px;
      width: 180px;
      height: 60px;
    }

    @include respond-below(xl) {
      top: 50px;
      left: 40px;
      width: 165px;
      height: 55px;
    }

    @include respond-below(md) {
      width: 131px;
      height: 43px;
      left: 15px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .button {
    position: absolute;
    top: 277px;
    left: 80px;
    display: flex;
    align-items: center;

    .reference {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    @include respond-below(xxl) {
      top: 291px;
      left: 40px;
    }

    @include respond-below(xl) {
      top: 55px;
      left: 643px;
    }

    @include respond-below(md) {
      top: 43px;
      left: 297px;
    }

    .icon {
      width: 48px;
      height: 48px;
      margin-right: 4px;

      @include respond-below(xxl) {
        width: 45px;
        height: 45px;
      }

      @include respond-below(md) {
        width: 38px;
        height: 38px;
      }
    }

    .label {
      font-family: $unbounded;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: $white;

      text-decoration: none;

      @include respond-below(xl) {
        display: none;
      }
    }
  }

  .copyright {
    position: absolute;
    top: 365px;
    left: 80px;
    max-width: 145px;

    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    color: rgba($white, 0.6);

    @include respond-below(xxl) {
      top: 366px;
      left: 40px;
      max-width: 127px;
      font-size: 14px;
      line-height: 135%;
    }

    @include respond-below(xl) {
      max-width: none;
      top: 683px;
      left: 242px;
    }

    @include respond-below(md) {
      top: 1023px;
      left: 53px;
    }
  }

  .support {
    position: absolute;
    top: 85px;
    left: 418px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include respond-below(xxl) {
      top: 56px;
      left: 296px;
      gap: 6px;
    }

    @include respond-below(xl) {
      top: 53px;
      left: 389px;
    }

    @include respond-below(md) {
      top: 123px;
      left: 15px;
      width: 320px;
      border-top: 1px solid rgba($light-green, 0.2);
      border-bottom: 1px solid rgba($light-green, 0.2);
      padding: 30px 0;
    }

    .label {
      font-family: $unbounded;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 130%;
      color: rgba($white, 0.6);

      @include respond-below(xxl) {
        font-size: 14px;
      }

      @include respond-below(md) {
        text-align: center;
      }
    }

    .email {
      display: flex;
      gap: 10px;

      @include respond-below(md) {
        justify-content: center;
      }

      .icon {
        width: 33px;
        height: 33px;

        @include respond-below(xxl) {
          width: 22px;
          height: 22px;
        }

        svg {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      .link {
        font-family: $unbounded;
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
        text-decoration-line: underline;
        color: #91F4A9;

        @include respond-below(xxl) {
          font-size: 16px;
        }
      }
    }
  }


  .documents {
    position: absolute;
    top: 191px;
    left: 418px;

    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 547px;

    @include respond-below(xxl) {
      top: 134px;
      left: 296px;
      gap: 10px;
      max-width: 160px;
    }

    @include respond-below(xl) {
      top: 154px;
      left: 40px;
      gap: 15px;
      max-width: 649px;
    }

    @include respond-below(md) {
      top: 264px;
      left: 15px;
      max-width: 320px;

    }

    .heading {
      font-family: $unbounded;
      font-weight: 300;
      font-size: 16px;
      line-height: 130%;
      color: rgba($white, 0.6);

      @include respond-below(xxl) {
        font-size: 14px;
      }

      @include respond-below(md) {
        text-align: center;
      }
    }

    .group {
      display: flex;
      gap: 40px;

      @include respond-below(xxl) {
        flex-direction: column;
        gap: 10px;
      }

      @include respond-below(xl) {
        display: none;
      }

      .subgroup {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include respond-below(xxl) {
          gap: 10px;
        }

        &.subgroup1 {
          width: 247px;

          @include respond-below(xxl) {
            width: auto;
          }
        }

        &.subgroup2 {
          width: 260px;
          gap: 20px;

          @include respond-below(xxl) {
            width: auto;
            gap: 10px;
          }
        }

        .link {
          font-family: $prompt;
          font-weight: 400;
          font-size: 18px;
          line-height: 130%;
          color: #F0F5EF;
          text-decoration: none;

          @include respond-below(xxl) {
            font-weight: 300;
            font-size: 14px;
            line-height: 135%;
          }
        }
      }
    }

    .groupMobile {
      // display: flex;
      // flex-direction: column;
      // flex-wrap: wrap;
      // gap: 15px 108px;
      // height: 87px;
      columns: 3;
      column-gap: 84px;

      @include respond-above(xl) {
        display: none;
      }

      @include respond-below(md) {
        columns: 2;
        column-gap: 20px;
      }

      .link {
        max-width: 160px;
        font-family: $prompt;
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        color: #F0F5EF;
        text-decoration: none;

        @include respond-below(xxl) {
          font-weight: 300;
          font-size: 14px;
          line-height: 135%;
        }

        @include respond-below(xl) {
          display: block;
          margin-bottom: 15px;
        }
      }
    }
  }



  .partner {
    position: absolute;
    top: 80px;
    left: 1070px;
    width: 729px;
    height: 327px;

    border: 1px solid $light-green;
    border-radius: 30px;

    @include respond-below(xxl) {
      top: 50px;
      left: 589px;
      width: 633px;
      height: 353px;
    }

    @include respond-below(xl) {
      top: 325px;
      left: 40px;
      width: 648px;
      height: 317px;
    }

    @include respond-below(md) {
      top: 497px;
      left: 15px;
      width: 320px;
      height: 496px;
      border-radius: 10px;
    }

    .heading {
      height: 61px;
      border: 1px solid $light-green;
      border-radius: 30px;
      display: flex;

      @include respond-below(xxl) {
        height: 57px;
      }

      @include respond-below(md) {
        display: none;
      }

      .leftTitle {
        display: flex;
        align-items: center;

        width: 241px;
        padding-left: 40px;

        background: #4BDB6E;
        border-radius: 30px;

        font-family: $unbounded;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: #141715;

        @include respond-below(xxl) {
          padding-left: 30px;
          width: 225px;
          font-size: 16px;
        }
      }

      .rightTitle {
        display: flex;
        align-items: center;

        padding-left: 40px;

        font-family: $unbounded;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: $light-green;

        @include respond-below(xxl) {
          padding-left: 30px;
          font-size: 16px;
        }
      }
    }

    .content {
      padding: 40px;
      display: flex;

      @include respond-below(md) {
        padding: 0;
        flex-direction: column;
      }

      .leftSection {

        .mobileTitle {
          display: flex;
          align-items: center;
          padding-left: 20px;

          background: #4BDB6E;
          border-radius: 10px;
          width: 320px;
          height: 51px;
          font-family: $prompt;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 130%;
          color: #141715;

          @include respond-above(md) {
            display: none;
          }
        }

        .wrapper {
          width: 135px;
          display: flex;
          flex-direction: column;
          gap: 8px;

          @include respond-below(md) {
            width: auto;
            padding: 25px 20px 30px 20px;
          }
        }

        .title {
          font-family: $prompt;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 130%;
          color: rgba($white, 0.8);
        }


        .field {
          display: flex;
          flex-direction: column;

          .label {
            font-family: $prompt;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: $white;

            @include respond-below(xxl) {
              font-weight: 300;
              font-size: 14px;
            }
          }

          .text {
            font-family: $prompt;
            font-weight: 300;
            font-size: 16px;
            line-height: 130%;
            color: rgba($white, 0.8);

            @include respond-below(xxl) {
              font-size: 14px;
            }
          }
        }


        .group {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .item {
            display: flex;
            gap: 4px;

            .mark {
              margin-top: 6px;
              width: 6px;
              height: 6px;
              border-radius: 100%;
              background: $light-green;
            }

            .text {
              width: 125px;

              font-family: $prompt;
              font-weight: 300;
              font-size: 14px;
              line-height: 135%;
              color: rgba($white, 0.8);

              @include respond-below(md) {
                width: auto;
              }
            }
          }
        }
      }

      .divider {
        width: 1px;
        height: 186px;
        margin: 0 60px;
        background: $light-green;
        border-radius: 30px;

        @include respond-below(xxl) {
          height: 176px;
          margin: 0 40px;
        }

        @include respond-below(md) {
          display: none;
        }
      }

      .rightSection {

        .mobileTitle {
          display: flex;
          align-items: center;
          padding-left: 20px;

          background: rgba($light-green, 0.15);
          border-radius: 10px;
          width: 320px;
          height: 51px;
          font-family: $prompt;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 130%;
          color: $light-green;

          @include respond-above(md) {
            display: none;
          }
        }

        .wrapper {
          display: flex;
          flex-direction: column;
          gap: 30px;

          @include respond-below(xxl) {
            gap: 20px;
          }

          @include respond-below(md) {
            gap: 15px;
            padding: 25px 20px 30px 20px;
          }
        }

        .field {
          display: flex;
          flex-direction: column;

          .label {
            font-family: $prompt;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: $white;

            @include respond-below(xxl) {
              font-weight: 300;
              font-size: 14px;
            }
          }

          .text {
            font-family: $prompt;
            font-weight: 300;
            font-size: 16px;
            line-height: 130%;
            color: rgba($white, 0.8);

            @include respond-below(xxl) {
              font-size: 14px;
            }
          }
        }

        .pair {
          display: flex;
          gap: 80px;
        }

      }
    }
  }


}