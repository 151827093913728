@import '~static/styles/mixins';
@import '~static/styles/variables';

.FirstClass {
  max-width: 1880px;
  margin: 0 auto 140px;
  padding: 0 80px;

  @include respond-below(xxl) {
    padding: 0 60px;
    max-width: 1326px;
  }

  @include respond-below(xl) {
    padding: 0 40px;
    max-width: 728px;
  }

  @include respond-below(md) {
    padding: 0 15px;
    margin: 0 auto 160px;
    max-width: 350px;
  }

  .article {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 100px;

    @include respond-below(xxl) {
      gap: 5px;
      margin-bottom: 60px;
    }

    @include respond-below(md) {
      margin-bottom: 30px;
    }

    .heading {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: #35A751;

      @include respond-below(xxl) {
        font-size: 18px;
      }

      @include respond-below(xl) {
        text-align: center;
      }

      @include respond-below(md) {
        font-size: 14px;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-below(xl) {
        flex-direction: column;
        text-align: center;
        gap: 20px;
      }

      @include respond-below(md) {
        gap: 15px;
      }

      .title {
        max-width: 530px;
        font-weight: 600;
        font-size: 64px;
        color: $black;

        @include respond-below(xxl) {
          max-width: 390px;
          font-size: 48px;
        }

        @include respond-below(md) {
          max-width: 320px;
          font-size: 32px;
        }
      }

      .text {
        max-width: 593px;
        font-weight: 300;
        font-size: 24px;
        color: $gray;

        @include respond-below(xxl) {
          max-width: 429px;
          font-size: 18px;
        }

        @include respond-below(xl) {
          max-width: 594px;
        }

        @include respond-below(md) {
          max-width: 320px;
          font-size: 14px;
        }
      }
    }
  }

  .group {
    display: flex;
    flex-direction: column;

    .card {
      border-bottom: 1px solid #D1D9D3;

      min-height: 190px;
      padding: 0 40px;

      display: flex;
      align-items: center;

      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.1s ease-out;

      @include respond-below(xxl) {
        min-height: auto;
        height: 133px;
      }

      @include respond-below(xl) {
        min-height: auto;
        height: 100px;
        padding: 0 20px;
      }

      @include respond-below(md) {
        height: auto;
        padding: 15px;
      }

      &:first-child {
        border-top: 1px solid #D1D9D3;
      }

      &:hover {
        background-color: rgba(61, 181, 90, 0.15);

        .text {
          color: $green;
        }

        .image {
          opacity: 1;
          filter: none;
        }
      }

      .number {
        font-weight: 600;
        font-size: 64px;
        line-height: 110%;
        color: $green;
        margin-right: 50px;


        @include respond-below(xxl) {
          font-size: 48px;
          margin-right: 30px;
        }

        @include respond-below(xl) {
          font-size: 40px;
          line-height: 60px;
          margin-right: 20px;
        }

        @include respond-below(md) {
          margin-right: 15px;
        }
      }

      .text {
        font-weight: 600;
        font-size: 40px;
        max-width: 1148px;
        transition: all 0.1s ease-out;

        @include respond-below(xxl) {
          font-size: 24px;
          max-width: 722px;
        }

        @include respond-below(xl) {
          font-size: 20px;
          line-height: 130%;
          max-width: 442px;
        }

        @include respond-below(md) {
          font-size: 16px;
          line-height: 110%;
          max-width: 214px;
        }
      }

      .image {
        position: absolute;
        right: 40px;
        top: 20%;
        width: 274px;
        opacity: 0.6;
        filter: blur(7.5px);
        transition: all 0.1s ease-out;

        @include respond-below(xxl) {
          width: 220px;
        }

        @include respond-below(xl) {
          width: 110px;
          right: 10px;
        }

        @include respond-below(md) {
          width: 90px;
          height: 90px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          right: -40px;
        }

        img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}