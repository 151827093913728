@import '~static/styles/mixins';
@import '~static/styles/variables';

.FirstTicket {
  max-width: 1880px;
  margin: 0 auto 140px;
  padding: 0 80px;

  @include respond-below(xxl) {
    padding: 0 60px;
    max-width: 1326px;
  }

  @include respond-below(xl) {
    padding: 0 40px;
    max-width: 728px;
  }

  @include respond-below(md) {
    padding: 0 15px;
    max-width: 350px;
    margin: 0 auto 100px;
  }

  .card {
    background-color: #3DB55A;
    border-radius: 30px;
    padding: 60px 148px 60px 394px;

    display: flex;
    gap: 120px;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include respond-below(xxl) {
      padding: 60px 96px 60px 274px;
      gap: 68px;
    }

    @include respond-below(xl) {
      padding: 40px 40px 40px 304px;

      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    @include respond-below(md) {
      align-items: center;
      padding: 120px 20px 40px 20px;
      gap: 15px;
    }

    .title {
      max-width: 353px;
      font-weight: 600;
      font-size: 64px;
      color: $white;
      line-height: 110%;

      @include respond-below(xxl) {
        max-width: 265px;
        font-size: 48px;
      }

      @include respond-below(md) {
        max-width: 177px;
        font-size: 32px;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      gap: 20px;

      @include respond-below(xxl) {
        gap: 15px;
      }

      @include respond-below(md) {
        align-items: center;
      }

      .text {
        max-width: 705px;
        font-weight: 300;
        font-size: 24px;
        line-height: 150%;
        color: $white;

        @include respond-below(xxl) {
          max-width: 543px;
          font-size: 18px;
        }

        @include respond-below(md) {
          font-size: 14px;
          line-height: 135%;
          text-align: center;
        }
      }

      .button {
        display: flex;
        align-items: center;

        text-decoration: none;

        .icon {
          margin-right: 4px;

          @include respond-below(md) {
            width: 38px;
            height: 38px;
          }
        }

        span {
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
          color: $white;

          @include respond-below(xxl) {
            font-size: 18px;
          }

          @include respond-below(md) {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }

    .image {
      position: absolute;
      left: -60px;
      top: -118px;

      @include respond-below(xxl) {
        width: 280px;
        left: -60px;
        top: -80px;
      }

      @include respond-below(xl) {
        width: auto;
        left: -70px;
        top: -60px;
      }

      @include respond-below(md) {
        width: 190px;
        top: -130px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

}